<template>
    <div class="position-relative" v-if="selected && params">
        <div class="novelty-arrow novelty-arrow_prev" v-show="active > 0">
            <button>
                <b-icon @click="toggleNovelty(false)" icon="chevron-left"></b-icon>
            </button>
        </div>
        <div class="novelty-modal">
            <div class="novelty-modal_header" :style="{background: `url(${ list[active].image_url ? list[active].image_url : '/assets/img/novelty-img.jpg' })`}"></div>
            <div style="background: white; padding: 25px 0">
                <div class="novelty-modal_body project-scroll">
                    <span>{{ new Date(list[active].publish_date).toLocaleDateString() }}</span>
                    <h1>{{ list[active].title }}</h1>
                    <div v-html="list[active].body"></div>
                </div>
            </div>
        </div>
        <div class="novelty-arrow novelty-arrow_next" @click="toggleNovelty(true)" v-show="active + 1 !== list.length">
            <button>
                <b-icon icon="chevron-right"></b-icon>
            </button>
        </div>
        <div class="novelty-arrow_mobile">
            <button
                v-show="active > 0"
                @click="toggleNovelty(false)">
                    <b-icon icon="chevron-left"></b-icon>
            </button>
            <button
                v-show="active + 1 !== list.length"
                @click="toggleNovelty(true)">
                    <b-icon icon="chevron-right"></b-icon>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Novelty",
    data() {
        return {
            slidedNew: 0
        }
    },
    computed: {
        params() {
            return this.$store.getters.activeModalParams
        },
        list() {
            return this.params.list
        },
        selected() {
            return this.params.selected
        },
        active() {
            return this.list ? this.list.findIndex(x => x.slug === this.selected) + this.slidedNew : ''
        }
    },
    methods: {
        toggleNovelty(direction) {
            direction ? this.slidedNew += 1 : this.slidedNew -= 1;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .novelty-arrow {
        position: absolute;

        &_mobile {
            background: white;
            display: none;
            align-items: center;
            justify-content: center;
            padding: 15px 0;
            border-radius: 0 0 10px 10px;

            @include only-lg-down {
                display: flex;
            }


            & button {
                background: none;
                outline: none;
                border: none;
            }

            & svg {
                color: var(--main-dark);
                font-size: 20px;

                &:hover {
                    color: black;
                }
            }
        }

        @include only-lg-down {
            display: none !important;
        }

        & button {
            background: none;
            outline: none;
            border: none;
        }

        & svg {
            color: white;
            font-size: 40px;
            cursor: pointer;

            &:hover {
                color: var(--main-app);
            }
        }

        &_prev {
            height: 100%;
            display: flex;
            align-items: center;
            top: 0;
            right: 100%;
            padding-right: 25px;
        }

        &_next {
            height: 100%;
            display: flex;
            align-items: center;
            top: 0;
            left: 100%;
            padding-left: 25px;
        }
    }

    .novelty-modal {
        overflow: hidden;

        @include only-md-up {
            border-radius: 0 0 10px 01px;
        }

        &_header {
            height: 207px;
            background-size: cover !important;
            background-position: center center !important;
            border-radius: 10px 10px 0 0;
        }

        &_body {
            padding: 25px;
            height: 345px;
            background: white;
            font-family: Montserrat-Medium, sans-serif;
            overflow-y: auto;
            width: 98%;

            & h1 {
                margin-bottom: 25px;
            }

            & span {
                color: var(--main-dark-paper);
                display: block;
            }

            @include only-xs {
                padding: 30px 20px;
            }
        }
    }
</style>